import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';
import styled, { css } from 'styled-components';
import {
  BaseHeader6Styles,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import {
  forScreenAtMost680pxWide,
  forScreenAtMost1200pxWide,
} from '../../styles/mediaQueries';
import tocElementAccent from '../../assets/images/accentArrow.svg';
import clock from '../../assets/images/clock.svg';

const TableOfContentsColumn = styled.div`
  flex: 0 1 30%;
  min-width: 300px;
  padding: 20px 100px 0 0;
  text-align: center;

  ${forScreenAtMost680pxWide(css`
    display: none;
  `)}
`;

const TableOfContentsUpheader = styled(Upheader)`
  text-align: left;
  margin-bottom: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ReadTimeWrapper = styled.div`
  margin-bottom: 60px;
  border-bottom: 2px solid
    ${({ theme }) => theme.colors.backgrounds.secondaryPair};
`;

const ReadTime = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: left;
  margin-bottom: 20px;
`;

const TableOfContentsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    ${BaseHeader6Styles}
    position: relative;
    margin: 0 0 24px;
    transition: all 0.3s;

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.texts.delicateAccented};
      transition: all 0.3s;
    }

    :before {
      content: '';
      position: absolute;
      left: -28px;
      top: 4px;
      width: 28px;
      height: 8px;
      display: inline-block;
      opacity: 0;
      transition: all 0.3s;
      mask-image: url('${tocElementAccent}');
      background-color: ${({ theme }) => theme.colors.accents.secondary};
    }
    :hover {
      padding-left: 20px;

      :before {
        opacity: 1;
      }

      a {
        color: ${({ theme }) => theme.colors.texts.tertiaryStronger};
      }
    }
  }

  ${forScreenAtMost1200pxWide(css`
    li {
      :before {
        left: -15px;
      }
    }
  `)}
`;

const TableOfContentsListItem = styled.li`
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.texts.delicateAccented};
`;

export const validId = (id: string): string =>
  id.replace(/[^a-zA-Z0-9_-]/g, '');

const TableOfContents = ({
  readTime,
  tableOfContents,
}: {
  readTime: number;
  tableOfContents: string[];
}): JSX.Element => {
  return (
    <TableOfContentsColumn>
      <ReadTimeWrapper>
        <ReadTime>
          <img src={clock} />
          {readTime} min read
        </ReadTime>
      </ReadTimeWrapper>
      <TableOfContentsUpheader $color="delicateAccented">
        Content
      </TableOfContentsUpheader>
      <TableOfContentsList>
        {tableOfContents.map((element) => (
          <TableOfContentsListItem key={element}>
            <AnchorLink title={element} to={`#${validId(element)}`} />
          </TableOfContentsListItem>
        ))}
      </TableOfContentsList>
    </TableOfContentsColumn>
  );
};

export default TableOfContents;
