import React from 'react';

export const validId = (id: string): string =>
  id.replace(/[^a-zA-Z0-9_-]/g, '');

const Header = (props: {
  node: { children: Array<{ text: string }>; style: string };
  children?: React.ReactNode;
}): JSX.Element => {
  return React.createElement(
    props.node.style,
    { id: validId(props.node.children[0].text) },
    props.children,
  );
};

export default Header;
