import React from 'react';
import styled from 'styled-components';

import quote from '../../../assets/images/quote.svg';

const Blockquote = ({
  children,
  ...props
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <blockquote {...props}>{children}</blockquote>;
};

export default styled(Blockquote)`
  color: ${({ theme }) => theme.colors.texts.tertiary};
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  font-style: normal;
  padding: 30px;

  &::before {
    content: url('${quote}');
    display: block;
  }
`;
