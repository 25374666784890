import React from 'react';
import styled, { css } from 'styled-components';
import { Title } from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';

const MainSection = ({
  title,
  tag,
}: {
  title: string;
  tag?: string;
}): JSX.Element => {
  return (
    <Wrapper>
      <MainContentColumnWrapper>
        <TextBlock>
          <SectionTitle>{title}</SectionTitle>
          {tag && <Tag>{tag}</Tag>}
        </TextBlock>
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 560px;
  text-align: center;

  ${forScreenAtMost680pxWide(css`
    :before {
      width: 50%;
    }
  `)}
`;

const TextBlock = styled.div`
  padding: 297px 0 283px 0;

  ${forScreenAtMost680pxWide(css`
    padding: 174px 0 0 0;
  `)}
`;

const SectionTitle = styled(Title).attrs({ $color: 'primaryAccented' })`
  max-width: 553px;
  margin: 0 auto;
  text-align: center;
`;

const Tag = styled.div`
  flex: 0 0;
  min-height: 24px;
  width: fit-content;
  background-color: rgba(222, 222, 222, 0.2);
  padding: 5px 15px 5px 15px;
  border-radius: 50px;
  margin: 30px auto;
`;

export default MainSection;
