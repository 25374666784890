import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/layout/Layout';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import { FooterBoxContent } from '../../components/layout/Footer';
import IntroSection from '../../pages-components/use-cases/IntroSection';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage, {
  IBackgroundImageProps,
} from 'gatsby-background-image';
import TableOfContents from '../../pages-components/use-cases/TableOfContents';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import BlockContent, {
  BlockContentNode,
  BlockRenderer,
  tableOfContentsTags,
} from '../../components/atoms/BlockContent/BlockContent';
import { YouTubeRender } from '../../components/atoms/BlockContent/MediaContent';
import { AltImageRenderer } from '../../components/atoms/BlockContent/AltImage';

export const pageQuery = graphql`
  query SanityUseCaseQuery($id: String!) {
    sanityUsecases(id: { eq: $id }) {
      title
      id
      tag
      readTime
      _rawDescription
      description {
        style
        children {
          text
        }
      }
      abstract
      image {
        asset {
          url
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
    overlay: file(relativePath: { eq: "images/passthrough-overlay.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    placeholder: file(relativePath: { eq: "images/use-case-placeholder.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const UseCaseContent = styled.div`
  background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
  color: ${({ theme }) => theme.colors.texts.delicateStronger};
  padding-top: 50px;
`;

const ContentColumnWrapper = styled(MainContentColumnWrapper)`
  display: flex;
  flex-wrap: nowrap;
`;

const HeaderBackgroundImage = styled(BackgroundImage)`
  background-position: top;
  background-image: radial-gradient(
    87.75% 87.75% at 72.88% 12.25%,
    #175e67 0%,
    #041a23 100%
  );
`;

interface UseCaseProps {
  data: {
    sanityUsecases: {
      title: string;
      abstract: string;
      readTime: number;
      tag: string;
      description: Array<BlockContentNode>;
      _rawDescription: string;
      image?: {
        asset: {
          url: string;
          gatsbyImageData: IGatsbyImageData;
        };
      };
    };
    overlay: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    placeholder: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  errors?: Array<string>;
}

const UseCase = ({
  data: { sanityUsecases, overlay, placeholder },
}: UseCaseProps): JSX.Element => {
  const bgImage = (
    convertToBgImage(
      [
        sanityUsecases.image
          ? sanityUsecases.image.asset.gatsbyImageData
          : placeholder.childImageSharp.gatsbyImageData,
        // TODO: didn't have time here to find what kind of blending gatsby allows
        overlay.childImageSharp.gatsbyImageData,
        `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))`,
      ].reverse(),
      // TODO: that's quite ugly, but i didn't find any docs on how to do it better
    ) as IBackgroundImageProps
  ).fluid;

  const tableOfContents = sanityUsecases.description
    .filter(
      (block: { style: string }) =>
        tableOfContentsTags.indexOf(block.style) !== -1,
    )
    .map((block: { style: string; children: Array<{ text: string }> }) => {
      return block.children[0].text;
    });

  const ogImage = sanityUsecases.image
    ? `${sanityUsecases.image.asset.url}?w=2400`
    : undefined;

  return (
    <Layout
      description={sanityUsecases.abstract}
      footerBoxContent={FooterBoxContent.None}
      ogImage={ogImage}
      title={sanityUsecases.title}
    >
      <HeaderBackgroundImage fluid={bgImage}>
        <MainContentColumnWrapper>
          <IntroSection tag={sanityUsecases.tag} title={sanityUsecases.title} />
        </MainContentColumnWrapper>
      </HeaderBackgroundImage>
      <UseCaseContent>
        <ContentColumnWrapper>
          <TableOfContents
            readTime={sanityUsecases.readTime}
            tableOfContents={tableOfContents}
          />
          <BlockContent
            blocks={sanityUsecases._rawDescription}
            dataset={process.env.GATSBY_SANITY_DATASET}
            projectId={process.env.GATSBY_SANITY_PROJECT_ID}
            serializers={{
              types: {
                block: BlockRenderer,
                youtube: YouTubeRender,
                altImage: AltImageRenderer,
              },
            }}
          />
        </ContentColumnWrapper>
      </UseCaseContent>
    </Layout>
  );
};

export default UseCase;
