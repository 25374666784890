import React from 'react';
import blockContentToHyperscript from '@sanity/block-content-to-hyperscript/lib';

interface Props {
  node: {
    alt?: string;
  };
}

export const AltImageRenderer = (props: Props): React.ReactNode => {
  const url = blockContentToHyperscript.getImageUrl(props);

  return (
    <figure>
      <img alt={props.node.alt} src={url} />
    </figure>
  );
};
