import React from 'react';
import getYouTubeId from 'get-youtube-id';
import YouTube from 'react-youtube';

export interface YouTubeContentNode {
  url: string;
  _type: string;
}

export interface YouTubeRenderProps {
  node: YouTubeContentNode;
  children: JSX.Element;
  options: {
    ignoreUnknownTypes?: boolean;
    // has additional options, but not necessary right now to describe them
  };
  isInline: boolean | undefined;
}

export const YouTubeRender = (props: YouTubeRenderProps) => {
  const { node } = props;

  if (node.url) {
    const { url } = node;
    const id = getYouTubeId(url);

    const opts = {
      height: '400',
      width: '640',
    };

    if (!id) {
      return null;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <YouTube opts={opts} videoId={id} />
      </div>
    );
  }

  return null;
};
