import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import listElementAccent from '../../../assets/images/buttonAccent.svg';
import { BaseTextMediumStyles } from '../Typography/Typography';
import Blockquote from './Blockquote';
import Header from './Header';

export interface BlockContentNode {
  style: string;
  url?: string;
  children: Array<{
    text: string;
  }>;
}

export interface BlockRenderProps {
  node: BlockContentNode;
  children: JSX.Element;
  options: {
    ignoreUnknownTypes?: boolean;
    // has additional options, but not necessary right now to describe them
  };
  isInline: boolean | undefined;
}

export const tableOfContentsTags = ['h2', 'h3'];

export const BlockRenderer = (props: BlockRenderProps): React.ReactNode => {
  if (tableOfContentsTags.indexOf(props.node.style) !== -1) {
    return <Header {...props} />;
  }

  if (props.node.style === 'blockquote') {
    // ignored - because these props are being removed nto to pass them further
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { node, options, isInline, ...rest } = props;

    return <Blockquote {...rest} />;
  }

  return BlockContent.defaultSerializers.types.block(props);
};

export default styled(BlockContent)`
  line-height: 24px;
  img {
    max-width: 100%;
    height: auto;
  }

  ol {
    margin: 0 0 26px;

    li {
      ${BaseTextMediumStyles}
      position: relative;
      &::before {
        content: '';
        width: 18px;
        display: inline-block;
      }

      &::marker {
        color: ${({ theme }) => theme.colors.accents.secondaryStronger};
      }
    }
  }
  }

  ul {
    margin: 0 0 26px;
    padding: 0;
    list-style: none;

    li {
      ${BaseTextMediumStyles}
      padding-left: 58px;
      position: relative;

      :before {
        content: '';
        position: absolute;
        left: 15px;
        top: 8px;
        width: 22px;
        height: 7px;
        display: inline-block;
        mask-image: url('${listElementAccent}');
        background-color: ${({ theme }) =>
          theme.colors.accents.secondaryStronger};
      }
    }
  }
`;
